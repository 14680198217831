import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { gql, useQuery } from '@apollo/client';
import { graphql as apolloGraphql } from '@apollo/client/react/hoc';
import { bindAll, flowRight as compose, isEmpty } from 'lodash';
import moment from 'moment';
import {
  compareValues,
  currencyFormat,
  getUsersPosition,
  isBrowser,
  impressionEvent,
} from '@firsttable/functions';
import styled from 'styled-components';
import {
  Box,
  Button,
  Col,
  Container,
  Row,
  Text,
  Title,
} from '@firsttable/web-components/atoms';
import MobileFilter from '@firsttable/web-components/molecules/MobileFilter';
import MobileFilterBtn from '@firsttable/web-components/molecules/MobileFilterBtn';
import PromotionModal from '@firsttable/web-components/organisms/PromotionModal';
import Cookies from 'js-cookie';
import { withAlert } from 'react-alert';
import ModalMap from '../components/organisms/ModalMap';
import ThemeLayout from '../layouts/layout';
import SEO from '../components/seo';
import CitySearchForm from '../components/organisms/Forms/CitySearchForm';
import CityHero from '../components/organisms/CityHero';
import GenericLayout from '../layouts/GenericLayout';
import RestaurantSearchList from '../components/organisms/RestaurantSearchList';
import SearchFilters from '../components/organisms/RestaurantSearchFilters';
import ModalContext from '../context/ModalContext';
import CityOtherSessions from '../components/organisms/CityOtherSessions/CityOtherSessions';
import PromoLayout from '../layouts/PromoLayout';
import {
  withCitySearchFilters,
  withCookie,
  withLocation,
  withModal,
  withSearchFilters,
} from '../hocs';
import use9PMRefresh from '../hooks/use9PMRefresh';

const Sticky = styled(Box)`
  position: sticky;
`;

// calculate distance restaurant is away
const distance = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }

  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') {
    dist *= 1.609344;
  }
  if (unit === 'N') {
    dist *= 0.8684;
  }

  return dist;
};

const useHookWithRefCallback = () => {
  const ref = useRef({});
  const setRef = useCallback((node) => {
    ref.current = { ...node, ...ref.current };
  }, []);

  return [ref, setRef];
};

const CityDisplay = ({
  city,
  siteConfig,
  restaurantsToShow,
  selectedDate,
  session,
  restaurantIds,
  userLat,
  userLng,
  processingSearch,
  clearAllFilters,
  handleMapView,
  restaurantStatuses,
  restaurantCount,
  searchFilterState,
  ...props
}) => {
  use9PMRefresh();
  const { showModalFunc, hideModalFunc } = useContext(ModalContext);
  const [showState, setShowState] = useState(true);
  const [ref, setRef] = useHookWithRefCallback();
  const [showFilterBtns, setShowFilterBtns] = useState(false);
  const restaurantToShowIds = restaurantsToShow.map((item) => item.foreignId);

  // set availability search state based on the users selectedDate
  // we can then use to determine if there are not results due to the selected date
  useEffect(() => {
    if (selectedDate !== 'any' && !isEmpty(ref.current)) {
      const val = Object.keys(ref.current)
        .filter((rId) => restaurantToShowIds.indexOf(+rId) !== -1)
        .filter(
          (r) =>
            ref.current[r] &&
            ref.current[r][selectedDate] &&
            ref.current[r][selectedDate].available,
        );
      setShowState(val.length > 0);
    } else {
      setShowState(true);
    }
  }, [selectedDate, ref.current]);

  // show/hide filter buttons by scroll
  useEffect(() => {
    const onScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 400 && !showFilterBtns) {
        setShowFilterBtns(true);
      }
      if (scrollTop <= 400 && showFilterBtns) {
        setShowFilterBtns(false);
      }
    };
    window.addEventListener('scroll', onScroll, false);
    return () => window.removeEventListener('scroll', onScroll, false);
  }, [showFilterBtns]);

  useEffect(() => {
    if (restaurantsToShow.length > 0) {
      impressionEvent('city page', restaurantsToShow);
    }
  }, []);

  // show nothing for empty city pages
  if (restaurantCount === 0) {
    return null;
  }

  if (restaurantsToShow.length > 0 && showState) {
    return (
      <Row>
        <Col width={[1, null, 3 / 4]}>
          <RestaurantSearchList
            city={city}
            restaurants={restaurantsToShow}
            selectedDate={selectedDate}
            showMap={false}
            session={session}
            restaurantIds={restaurantIds}
            userLat={userLat}
            userLng={userLng}
            isLoading={processingSearch}
            restaurantAvailabilityRef={setRef}
            restaurantStatuses={restaurantStatuses}
            searchFilterState={searchFilterState}
          />
          <MobileFilter
            position="sticky"
            display={['flex', null, 'none']}
            style={{
              opacity: showFilterBtns ? 1 : 0,
              visibility: showFilterBtns ? 'visible' : 'hidden',
            }}
          >
            <MobileFilterBtn
              kind="left"
              icon="m-map"
              onClick={() => handleMapView()}
            />
            <MobileFilterBtn
              kind="right"
              icon="m-filter"
              onClick={() =>
                showModalFunc(
                  <Box p="24px 24px 70px" position="relative">
                    <SearchFilters
                      handleMapView={() => handleMapView()}
                      showMap={false}
                      session={session}
                      {...props}
                    />
                    <MobileFilter position="fixed">
                      <MobileFilterBtn
                        kind="left"
                        icon="m-map"
                        onClick={() => handleMapView()}
                      />
                      <MobileFilterBtn
                        kind="right"
                        icon="m-close-m-bold"
                        onClick={() => hideModalFunc()}
                      />
                    </MobileFilter>
                  </Box>,
                  { disableCloseBtn: true, type: 'none' },
                )
              }
            />
          </MobileFilter>
        </Col>
        <Col width={[1, null, 1 / 4]} mb={55} display={['none', null, 'block']}>
          <Box
            borderLeft={[null, null, '1px solid']}
            borderColor={[null, null, 'grayscale.300']}
            pl={[null, null, 'l']}
            height="100%"
          >
            <Sticky top={25}>
              <SearchFilters
                handleMapView={() => handleMapView()}
                showMap={false}
                session={session}
                {...props}
              />
            </Sticky>
          </Box>
        </Col>
      </Row>
    );
  }

  return (
    <span>
      <Box mb={['xxl', null, 70]}>
        <Title>Nothing found</Title>
        <Text>
          We can&rsquo;t find availability for what you’re looking for. Try
          clearing your search filter.
        </Text>
        <Button kind="cta" size="s" onClick={() => clearAllFilters()}>
          Reset search filters
        </Button>
      </Box>
    </span>
  );
};

CityDisplay.propTypes = {
  city: PropTypes.shape().isRequired,
  siteConfig: PropTypes.shape().isRequired,
  restaurantsToShow: PropTypes.array,
  selectedDate: PropTypes.string,
  session: PropTypes.string,
  restaurantIds: PropTypes.array,
  userLat: PropTypes.number,
  userLng: PropTypes.number,
  processingSearch: PropTypes.bool,
  clearAllFilters: PropTypes.func,
  handleFilters: PropTypes.func,
  handleCuisineFilters: PropTypes.func,
  selectedFilters: PropTypes.array,
  selectedCuisineFilters: PropTypes.array,
  restaurants: PropTypes.object,
  clearFilters: PropTypes.func,
  handleMapView: PropTypes.func,
  restaurantStatuses: PropTypes.array,
  restaurantScores: PropTypes.array,
  restaurantCount: PropTypes.number,
  searchFilterState: PropTypes.any,
};

const getSessionTypeFromUrl = (pathname) => {
  const hasSessionPath = pathname.match(/(lunch|breakfast)/g);
  return hasSessionPath ? hasSessionPath[0] : null;
};

const USER_PROMO_CODE = gql`
  query getUserPromoCode {
    Member {
      expiringCredit {
        edges {
          node {
            title
            currency
            amount
            expires
          }
        }
      }
    }
  }
`;

const SignUpModal = ({ userSignUpPromoCode }) => {
  const [showSignUp, setShowSignUp] = useState(false);
  const [promoData, setPromoData] = useState(null);
  const { hideModalFunc } = useContext(ModalContext);

  useEffect(() => {
    setShowSignUp(true);
    setPromoData(userSignUpPromoCode);
    Cookies.remove('userSignedUp');
  }, [userSignUpPromoCode]);

  if (showSignUp && promoData) {
    const {
      expiringCredit: {
        edges: [credit],
      },
    } = userSignUpPromoCode;
    return (
      <PromotionModal
        title="Sign Up Complete"
        titleBody={
          credit?.node?.currency
            ? `You've got ${currencyFormat(
                credit.node.amount,
                credit.node.currency,
              )} free credit to use towards your first booking!`
            : null
        }
        content="Thanks for registering and welcome to First Table.
          Book a First Table at our partner restaurants and get 50% off the food bill for two to four people."
        shareButtonText="Get Started"
        showPromotion={false}
        hideModal={hideModalFunc}
      />
    );
  }
  return null;
};

SignUpModal.propTypes = {
  userSignUpPromoCode: PropTypes.shape(),
};

class CityPageTemplate extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this, [
      'getCurrentPosition',
      'handleCitySort',
      'clearAllFilters',
      'showGeoLocationErrorMessage',
      // 'escFunction',
    ]);
    this.state = {
      geoLocationError: false, // if we got an error from retrieving location
      userLat: 0,
      userLng: 0,
      processingSearch: false,
      shownGeoLocationError: false,
    };
  }

  async componentDidMount() {
    const {
      searchFilterState: { filters, cuisine, showMap },
      searchFilterActions: { handleMapView },
      location: { pathname },
      restaurantSearch: { restaurantSort, session, selectedDate },
      updateSearchFiltersQuery,
    } = this.props;
    if (showMap) {
      // Hide map if returning back to the city page
      handleMapView();
    }
    if (filters.length || cuisine.length) {
      await this.clearAllFilters();
    }
    // set the search session to the session in the URL
    const sessionFromUrl = getSessionTypeFromUrl(pathname);
    if (sessionFromUrl) {
      await updateSearchFiltersQuery({
        ...updateSearchFiltersQuery(),
        restaurantSort,
        selectedDate,
        session: sessionFromUrl,
      });
    }
    const now = moment();
    const date = selectedDate !== 'any' ? moment(selectedDate) : now;
    const isPast = date.isSameOrBefore(now);
    // reset selected date if it's in the past from an old search
    if (isPast && selectedDate !== 'any') {
      await updateSearchFiltersQuery({
        ...updateSearchFiltersQuery(),
        restaurantSort,
        selectedDate: 'any',
        session,
      });
    }
  }

  componentDidUpdate() {
    const { showModal, userSignUpPromoCode, newUserSignUp } = this.props;
    const { geoLocationError, shownGeoLocationError } = this.state;
    if (geoLocationError && !shownGeoLocationError) {
      this.showGeoLocationErrorMessage();
    }

    if (newUserSignUp && userSignUpPromoCode) {
      showModal(
        <SignUpModal
          userSignUpPromoCode={userSignUpPromoCode}
          newUserSignUp={newUserSignUp}
        />,
        { timeout: 1000, type: 'narrow' },
      );
    }
  }

  componentWillUnmount() {
    const {
      searchFilterActions: { clearCuisineFilters, clearFilters },
    } = this.props;
    clearCuisineFilters();
    clearFilters();
  }

  async getCurrentPosition() {
    const {
      enableHighAccuracy,
      timeout,
      maximumAge,
      updateSearchFiltersQuery,
    } = this.props;

    try {
      this.setState({
        processingSearch: true, // handle the delay in processing the browser location
      });
      const { coords } = await getUsersPosition({
        enableHighAccuracy,
        timeout,
        maximumAge,
      });
      const { latitude, longitude } = coords;
      this.setState({
        userLat: latitude,
        userLng: longitude,
        processingSearch: false,
      });
      await updateSearchFiltersQuery({
        ...updateSearchFiltersQuery(),
        userLat: latitude,
        userLng: longitude,
      });
    } catch (error) {
      // Handle error
      this.setState({ geoLocationError: true, processingSearch: false });
    }
  }

  showGeoLocationErrorMessage() {
    const { alert } = this.props;
    alert.error(
      "We couldn't access your location so are unable to show restaurants near you",
    );
    this.setState({ shownGeoLocationError: true });
  }

  async clearAllFilters() {
    const {
      updateSearchFiltersQuery,
      searchFilterActions: { clearCuisineFilters, clearFilters },
    } = this.props;
    const restaurantSort = 'default';
    const session = 'dinner';
    const selectedDate = 'any';
    await updateSearchFiltersQuery({
      ...updateSearchFiltersQuery(),
      restaurantSort,
      session,
      selectedDate,
    });
    clearCuisineFilters();
    clearFilters();
  }

  // eslint-disable-next-line react/sort-comp
  async handleCitySort(search) {
    const { updateSearchFiltersQuery } = this.props;
    const { userLat, userLng } = this.state;
    const { restaurantSort, session, selectedDate } = search;

    if (restaurantSort === 'distance' && (!userLat || !userLng)) {
      await this.getCurrentPosition();
    }
    // update apollo local state
    await updateSearchFiltersQuery({
      ...updateSearchFiltersQuery(),
      restaurantSort,
      session,
      selectedDate,
    });
  }

  render() {
    const {
      data: { subCities, restaurants, city, siteConfig },
      restaurantStatuses,
      restaurantSearch: {
        restaurantSort,
        userLat,
        userLng,
        session,
        selectedDate,
      },
      location: { pathname },
      searchFilterState,
      searchFilterActions: { handleMapView },
      pageContext: { restaurantScores },
    } = this.props;

    // Coming Soon
    if (city.comingSoon) {
      return (
        <PromoLayout city={city} cityId={city.foreignId} isCityComingSoon />
      );
    }

    const { processingSearch } = this.state;
    const regionFilter = {};
    regionFilter.edges = subCities.edges.filter(
      ({ node }) => node.restaurants.edges.length > 0,
    );
    const hasSessionPath = pathname.match(/(lunch|breakfast)/g);
    const sessionType = hasSessionPath ? hasSessionPath[0] : session;

    const restaurantIds = restaurants.edges.map(({ node }) => node.id);
    const hasAmex =
      restaurants.edges.filter(({ node }) => node.acceptsAmex).length > 0;
    const hasAdditionalPeople =
      restaurants.edges.filter(({ node }) => node.additionalPeopleAllowed > 0)
        .length > 0;
    const hasLastTable =
      restaurants.edges.filter(({ node }) =>
        node.availabilitySessions.includes('dinner2'),
      ).length > 0;
    let metaTitle = city.metaTitleFormatted;
    if (city.metaTitleFormatted.includes('{session}') && sessionType) {
      metaTitle = metaTitle.replace(
        '{session}',
        sessionType.charAt(0).toUpperCase() + sessionType.slice(1),
      );
    }

    // convert restaurants to a list for easier filtering and sorting
    let restaurantsToShow = restaurants.edges
      .map(({ node }) => node)
      .filter((node) => {
        if (session === 'dinner') {
          return (
            node.availabilitySessions.includes('dinner') ||
            node.availabilitySessions.includes('dinner2')
          );
        }
        return node.availabilitySessions.includes(session);
      })
      .filter((node) => {
        // handle cuisine filter
        if (!searchFilterState.cuisine.length) {
          return node;
        }
        const cuisines = []; // create a list of cuisines for each restaurant..
        node.cuisines.edges.map(({ node: nodeCuisine }) => {
          cuisines.push(nodeCuisine.id);
          return true;
        });
        const inFilters = cuisines.filter((cuisine) =>
          searchFilterState.cuisine.includes(cuisine),
        );
        if (inFilters.length) {
          return node;
        }
        return false;
      })
      .filter((node) => {
        // handle other filters
        if (!searchFilterState.filters.length) {
          return node;
        }
        if (
          searchFilterState.filters.includes('acceptsAmex') &&
          node.acceptsAmex
        ) {
          return true;
        }
        if (
          searchFilterState.filters.includes('hasLastTable') &&
          node.availabilitySessions.includes('dinner2')
        ) {
          return true;
        }
        return !!(
          searchFilterState.filters.includes('additionalPeopleAllowed') &&
          node.additionalPeopleAllowed > 0
        );
      });

    if (userLat && userLng) {
      restaurantsToShow.map((node) => {
        // eslint-disable-next-line no-param-reassign
        node.distance = distance(
          parseFloat(node.lat),
          parseFloat(node.lng),
          parseFloat(userLat),
          parseFloat(userLng),
          'K',
        );
        return true;
      });
    }

    // default sort
    if (restaurantSort === 'default') {
      if (restaurantScores) {
        restaurantsToShow = restaurantScores
          .map((i) => restaurantsToShow.find((j) => j.foreignId === i))
          .filter((j) => j);
      }
    } else {
      restaurantsToShow.sort(
        compareValues(
          restaurantSort,
          restaurantSort === 'score' ? 'desc' : 'asc',
        ),
      );
      // Sorting by status then by on hold to get available restaurants to the top
      restaurantsToShow.sort(compareValues('status'));
    }
    restaurantsToShow.sort(compareValues('onHoldText'));
    if (searchFilterState.showMap) {
      return (
        <ThemeLayout>
          <ModalMap
            selectedDate={selectedDate}
            session={session}
            restaurantsToShow={restaurantsToShow}
            restaurantIds={restaurantIds}
            userLat={userLat}
            userLng={userLng}
            restaurants={restaurants}
            hasAmex={hasAmex}
            hasAdditionalPeople={hasAdditionalPeople}
            handleMapView={handleMapView}
            hasLastTable={hasLastTable}
          />
        </ThemeLayout>
      );
    }

    return (
      <GenericLayout menuTitle={city.menuTitle}>
        <SEO
          title={metaTitle}
          id={city.foreignId}
          description={city.metaDescription}
          ogImage={city.ogImage}
          metaTags={city.metaTags}
          slug={city.slug}
        />
        {!city.comingSoon && (
          <CityHero
            headerTitle={city.menuTitle}
            breadcrumbs={city.breadcrumbs.edges}
          />
        )}
        <Container>
          {!city.comingSoon && (
            <Row mt={[null, '-55px']} mb={['10px', '55px']}>
              <Col>
                <CitySearchForm
                  subCities={regionFilter}
                  handleCitySort={this.handleCitySort}
                  initialValues={{
                    restaurantSort,
                    selectedDate,
                    session,
                  }}
                />
              </Col>
            </Row>
          )}
          <CityDisplay
            city={city}
            siteConfig={siteConfig}
            restaurantsToShow={restaurantsToShow}
            selectedDate={selectedDate}
            session={sessionType}
            restaurantIds={restaurantIds}
            userLat={userLat}
            userLng={userLng}
            clearAllFilters={this.clearAllFilters}
            restaurants={restaurants}
            hasAmex={hasAmex}
            hasAdditionalPeople={hasAdditionalPeople}
            processingSearch={
              processingSearch || (!restaurantStatuses.length && isBrowser)
            }
            handleMapView={handleMapView}
            hasLastTable={hasLastTable}
            restaurantStatuses={restaurantStatuses}
            restaurantCount={restaurants.edges.length}
            searchFilterState={searchFilterState}
          />
          <CityOtherSessions city={city} sessionType={sessionType} />
        </Container>
      </GenericLayout>
    );
  }
}

CityPageTemplate.propTypes = {
  // https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions
  // https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
  enableHighAccuracy: PropTypes.bool,
  timeout: PropTypes.number,
  maximumAge: PropTypes.number,
  data: PropTypes.object.isRequired,
  updateSearchFiltersQuery: PropTypes.func.isRequired,
  restaurantSearch: PropTypes.shape(),
  location: PropTypes.shape(),
  searchFilterState: PropTypes.shape({
    cuisine: PropTypes.array,
    filters: PropTypes.array,
    showMap: PropTypes.bool,
  }),
  searchFilterActions: PropTypes.shape({
    setCuisineFilters: PropTypes.func,
    setFilters: PropTypes.func,
    clearCuisineFilters: PropTypes.func,
    clearFilters: PropTypes.func,
    handleMapView: PropTypes.func,
  }),
  pageContext: PropTypes.shape({
    restaurantScores: PropTypes.array,
  }),
  restaurantStatuses: PropTypes.array,
  alert: PropTypes.shape(),
  showModal: PropTypes.func.isRequired,
  userSignUpPromoCode: PropTypes.shape(),
  newUserSignUp: PropTypes.bool,
};

CityPageTemplate.defaultProps = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
  restaurantStatuses: [],
  newUserSignUp: false,
  userSignUpPromoCode: null,
};

const withPromoCodeQuery = (ComponentToWrap) => {
  const WithPromoCodeQuery = ({ newUserSignUp, ...props }) => {
    const [, setState] = useState(false);
    const { data, error } = useQuery(USER_PROMO_CODE, {
      skip: !newUserSignUp,
      fetchPolicy: 'network-only',
    });
    const userPromoCode = useRef(null);
    useEffect(() => {
      if (data) {
        const { Member: userSignUpPromoCode } = data;
        userPromoCode.current = userSignUpPromoCode;
        setState(true); // reload state
      }
      if (error) {
        Cookies.remove('userSignedUp');
        userPromoCode.current = null;
      }
    }, [data, error]);
    return (
      <ComponentToWrap
        newUserSignUp={newUserSignUp}
        userSignUpPromoCode={userPromoCode.current}
        {...props}
      />
    );
  };
  WithPromoCodeQuery.displayName = 'withPromoCodeQuery';
  WithPromoCodeQuery.propTypes = {
    newUserSignUp: PropTypes.any,
  };
  return WithPromoCodeQuery;
};

export default compose(
  apolloGraphql(
    gql`
      query getRestaurantStatus($restaurantId: [Int]) {
        restaurantStatuses: allRestaurants(restaurantId: $restaurantId) {
          id
          status
          onHoldText
        }
      }
    `,
    {
      // skip cities with no restaurants
      skip: ({ data: { restaurants } }) => restaurants.edges.length === 0,
      props: ({ data: { restaurantStatuses } }) => ({
        restaurantStatuses,
      }),
      options: ({ data: { restaurants } }) => ({
        variables: {
          restaurantId: restaurants.edges.map(({ node }) => node.foreignId),
        },
        fetchPolicy: 'network-only',
        ssr: false,
      }),
    },
  ),
  withModal,
  withAlert(),
  withLocation,
  withCookie,
  withPromoCodeQuery,
  withSearchFilters,
  withCitySearchFilters,
)(CityPageTemplate);

// variable taken from gatsby-node-js create page context
export const query = graphql`
  query getCityAndSubCities(
    $regionId: Int!
    $slug: String!
    $restaurantIds: [Int]
  ) {
    city(slug: { eq: $slug }) {
      id
      foreignId
      parentId
      title
      content
      menuTitle
      metaTitleFormatted
      metaDescription
      metaTags
      ogImage
      slug
      regionId
      comingSoon
      launchDate
      blocks {
        edges {
          node {
            id
            key
            title
            content
          }
        }
      }
      breadcrumbs {
        edges {
          node {
            id
            title
            menuTitle
            slug
          }
        }
      }
      breakfastRestaurants {
        edges {
          node {
            id
          }
        }
      }
      lunchRestaurants {
        edges {
          node {
            id
          }
        }
      }
    }
    siteConfig {
      currencySymbol
      currency
      country
      freeCreditAmount
    }
    subCities: allCity(filter: { regionId: { in: [$regionId] } }) {
      edges {
        node {
          id
          foreignId
          title
          menuTitle
          slug
          regionId
          parentId
          restaurants {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
    restaurants: allRestaurant(filter: { foreignId: { in: $restaurantIds } }) {
      edges {
        node {
          id
          foreignId
          siteId
          title
          content
          status
          onHoldText
          gallery
          logo {
            url
          }
          region {
            id
            title
            menuTitle
            slug
          }
          suburb {
            id
            title
            menuTitle
            slug
          }
          isNew
          featuredText
          slug
          openHours
          city
          discover
          mainsPriceRange
          acceptsAmex
          launchDate
          lat
          lng
          rating
          score
          bookingPrice
          priceInNZD
          approvedReviewsCount
          additionalPeopleAllowed
          cityId
          regionId
          regionRanking
          cityRanking
          availabilitySessions
          cuisines {
            edges {
              node {
                id: id
                label: title
                active
              }
            }
          }
        }
      }
    }
  }
`;
