import { useEffect, useState } from 'react';

const use9PMRefresh = () => {
  const [loadedAt, setLoadedAt] = useState(new Date()); // default value can be anything you want
  const [reloaded, setReloaded] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      const currentHourOfDay = new Date().getHours();
      const currentMinutes = new Date().getMinutes();
      const minuteCutOff = 0; // 0
      const hourCutOff = 21; // 9pm
      if (
        currentHourOfDay === hourCutOff && // check the current hour is the time to refresh
        currentMinutes === minuteCutOff && // check the correct minute
        ((minuteCutOff === 0 && loadedAt.getHours() < hourCutOff) ||
          minuteCutOff > 0) &&
        !reloaded // check this hasn't remounted already
      ) {
        setLoadedAt(new Date());
        setReloaded(true);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [loadedAt, reloaded]);
};

export default use9PMRefresh;
