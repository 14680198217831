import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';
import { breakpointsObject as bp } from '@firsttable/web-theme';
import board from './board.svg';
import Flex from '../../atoms/Flex';
import Box from '../../atoms/Box';
import Text from '../../atoms/Text';
import Title from '../../atoms/Title';
import Divider from '../../atoms/Divider';
import Button from '../../atoms/Button';

const Board = styled.div`
  text-align: center;
  ${media.greaterThan(bp.s)`
    width: 363px;
    height: 282px;
    padding-top: 38px;
    margin: -130px auto 34px;
    background: url(${board}) no-repeat 0 0;
  `};
`;

const PromotionModal = ({
  title,
  titleBody,
  content,
  shareButtonText,
  shareButtonAction,
  showPromotion,
  buttonIcon,
  hideModal,
}) => (
  <Flex
    height="100vh"
  >
    <Box pt={[null, '130px']} m="auto">
      <Box
        position="relative"
        width="100%"
        maxWidth="508px"
        p={['40px 16px 32px', '1px 64px 64px']}
        bg="white"
      >
        <Board>
          <Text color="grayscale.600" fontSize="s" mb="10px">
            {showPromotion && ('Promotion')}
              &nbsp;
          </Text>
          <Title size="h5" mb="13px" data-testid="signup__complete">
            {title}
          </Title>
          {titleBody && (
          <Box maxWidth={[null, '255px']} mx="auto" fontSize="s">
            <Text>
              {titleBody}
            </Text>
          </Box>
          )}
        </Board>
        <Divider mb="16px" display={[null, 'none']} />
        <Text textAlign="center">
          {content}
        </Text>
        <Box mt={['24px', '40px']}>
          <Button
            onClick={shareButtonAction || hideModal}
            icon={buttonIcon}
            kind="facebook-share"
            wide
            size="l"
            fontWeight="600"
          >
              &nbsp;&nbsp;
            {' '}
            {shareButtonText}
          </Button>
        </Box>
      </Box>
    </Box>
  </Flex>
);

PromotionModal.propTypes = {
  title: PropTypes.string.isRequired,
  titleBody: PropTypes.string,
  content: PropTypes.string.isRequired,
  shareButtonText: PropTypes.string.isRequired,
  hideModal: PropTypes.func,
  shareButtonAction: PropTypes.any,
  showPromotion: PropTypes.bool,
  buttonIcon: PropTypes.string,
};

PromotionModal.defaultProps = {
  shareButtonAction: '',
  showPromotion: true,
  titleBody: null,
  buttonIcon: '',
  hideModal: null,
};

export default PromotionModal;
