import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Icon } from '@firsttable/web-components/atoms';
import { Filter } from '@firsttable/web-components/organisms';
import styled from 'styled-components';
import { Restaurant as RestaurantType } from '@firsttable/types';
import useCuisineFilters from '../../../hooks/useCuisineFilters';
import withSearchFilters from '../../../hocs/withSearchFilters';

const StyledCloseButton = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  &:hover {
    opacity: 0.5;
  }
`;

type SearchFilterPropTypes = {
  handleMapView: () => void;
  restaurants: RestaurantType[];
  showMap: boolean;
  hasAdditionalPeople: boolean;
  hasAmex: boolean;
  hasLastTable: boolean;
  searchFilterState: any;
  searchFilterActions: any;
  session: string;
};
const SearchFilters = ({
  handleMapView,
  restaurants,
  showMap,
  hasAdditionalPeople,
  hasAmex,
  hasLastTable,
  searchFilterState,
  searchFilterActions,
  session,
}: SearchFilterPropTypes) => {
  const filters = useCuisineFilters(restaurants);
  const otherFilters = [];
  if (hasAdditionalPeople) {
    otherFilters.push({ id: 'additionalPeopleAllowed', label: 'Four+More' });
  }
  if (hasAmex) {
    otherFilters.push({ id: 'acceptsAmex', label: 'Accepts American Express' });
  }
  if (hasLastTable && session === 'dinner') {
    otherFilters.push({ id: 'hasLastTable', label: 'Last Table' });
  }
  return (
    <>
      <Box display={['none', null, 'flex']} mb={20}>
        <Button
          icon={showMap ? 'm-list' : 'm-map'}
          kind="outline"
          size="l"
          wide
          onClick={handleMapView}
          mr={10}
        >
          {showMap ? 'View list' : 'View map'}
        </Button>
        {showMap && (
          <StyledCloseButton
            width={30}
            height={30}
            right={0}
            top={0}
            role="button"
            onClick={handleMapView}
          >
            <Icon name="nav-close" />
          </StyledCloseButton>
        )}
      </Box>
      <Divider my="25px" />
      <Filter
        name="Cuisine"
        selectedValues={searchFilterState.cuisine}
        handleFilters={searchFilterActions.setCuisineFilters}
        filters={filters}
        clearFilters={searchFilterActions.clearCuisineFilters}
      />
      {otherFilters.length > 0 && (
        <Filter
          name="Filters"
          selectedValues={searchFilterState.filters}
          handleFilters={searchFilterActions.setFilters}
          filters={otherFilters}
          clearFilters={searchFilterActions.clearFilters}
        />
      )}
    </>
  );
};

SearchFilters.propTypes = {
  hasAdditionalPeople: PropTypes.bool,
  hasLastTable: PropTypes.bool,
  hasAmex: PropTypes.bool,
  handleMapView: PropTypes.func.isRequired,
  restaurants: PropTypes.object,
  showMap: PropTypes.bool,
  searchFilterState: PropTypes.shape({
    cuisine: PropTypes.array,
    filters: PropTypes.array,
  }),
  searchFilterActions: PropTypes.shape({
    setCuisineFilters: PropTypes.func,
    setFilters: PropTypes.func,
    clearCuisineFilters: PropTypes.func,
    clearFilters: PropTypes.func,
  }),
  session: PropTypes.string,
};

SearchFilters.defaultProps = {
  searchFilterActions: {
    setCuisineFilters: () => {},
    setFilters: () => {},
  },
  searchFilterState: {
    cuisine: [],
    filters: [],
  },
  restaurants: {},
  showMap: false,
  hasAdditionalPeople: false,
  hasLastTable: false,
  hasAmex: false,
  session: null,
};

export default withSearchFilters(SearchFilters);
