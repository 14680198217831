import React from 'react';
import PropTypes from 'prop-types';
import Box from '@firsttable/web-components/atoms/Box';
import { range } from '@firsttable/functions';
import RestaurantListItem from '../RestaurantListItem';
import Amex from '../../Amex';

export const LoadingRestaurantItem = () => (
  <Box display={['block', 'flex']} maxWidth="860px" mb="30px">
    <Box
      mr={[0, '32px']}
      mb={['15px', 0]}
      maxWidth={['100%', '200px']}
      style={{ flexShrink: 0 }}
      alignSelf="flex-start"
    >
      <Box
        className="bg-pulse-animation"
        height={[null, '194px']}
        width={[null, '200px']}
        bg="grayscale.300"
        display={['inline-block', 'block']}
        verticalAlign="top"
      />
    </Box>
    <Box flex={1} className="pulse-animation">
      <Box
        bg="grayscale.300"
        width={['60%', '41%']}
        height="19px"
        mb="38px"
        mt="10px"
      />
      <Box bg="grayscale.300" width="25%" height="8px" mb="15px" />
      <Box bg="grayscale.300" width="50%" height="8px" mb="38px" />
      <Box bg="grayscale.300" width="75%" height="12px" />
    </Box>
  </Box>
);

const RestaurantSearchList = ({
  restaurants,
  session,
  selectedDate,
  isLoading,
  ...props
}) => (
  <Box mb="55px">
    {isLoading ? (
      <span>
        {range(0, 4).map((i) => (
          <LoadingRestaurantItem key={i} />
        ))}
      </span>
    ) : (
      restaurants &&
      restaurants.map((node) => {
        // set session to display to dinner2 if no dinner
        let restaurantSessionToDisplay = session;
        if (
          session === 'dinner' &&
          !node.availabilitySessions.includes('dinner') &&
          node.availabilitySessions.includes('dinner2')
        ) {
          restaurantSessionToDisplay = 'dinner2';
        }
        return (
          <RestaurantListItem
            {...props}
            key={node.id}
            restaurant={node}
            availabilitySession={restaurantSessionToDisplay}
            selectedDate={selectedDate}
            Amex={Amex}
          />
        );
      })
    )}
  </Box>
);

RestaurantSearchList.propTypes = {
  restaurants: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectedDate: PropTypes.string,
  session: PropTypes.string,
  isLoading: PropTypes.bool,
};

RestaurantSearchList.defaultProps = {
  session: '',
  selectedDate: '',
  restaurants: {},
  isLoading: false,
};

export default RestaurantSearchList;
