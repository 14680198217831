import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from '@firsttable/web-components/molecules/BackgroundImage';
import {
  Box, Row, Col, Title, Container, Text, BodyTitle, Link, Breadcrumbs, Flex, Icon,
} from '@firsttable/web-components/atoms';
import NavLinkComponent from '../../atoms/NavLink';

const StyledImageBackground = ({ children, ...props }) => {
  const { desktop } = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "home-bgv3.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  // Set ImageData.
  const imageData = desktop.childImageSharp.fluid;
  return (
    <BackgroundImage
      imageData={imageData}
      {...props}
    >
      {children}
    </BackgroundImage>
  );
};
StyledImageBackground.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

const HeroCTA = styled(Box)`
  width: 400px;
  background: ${themeGet('colors.white')};
  transform: matrix(1, 0.03, -0.02, 1, 0, 0);
  .expandable-pill {
    white-space: nowrap;
    transition: 0.3s ease;
  }
  &:hover .expandable-pill {
    width: 185px;
  }
`;

const HeroCTABody = styled(Box)`
  transform: matrix(1, -0.03, 0.02, 1, 0, 0);
  padding: 35px;
  text-align: center;
`;

const HeroCTABackground = styled(Box)`
  height: 100%;
  background: ${themeGet('colors.grayscale.300')};
  transform: matrix(1, -0.03, -0.02, -1, 0, 0);
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
`;

const FaqLink = styled(Link)`
  position: absolute;
  bottom: -32px;
  left: 50%;
  transform: translate(-50%, 0);
  .arrow {
    flex-shrink: 0;
    transition: 0.2s ease-out transform;
    will-change: transform;
  }
`;

const CityHero = ({ headerTitle, breadcrumbs }) => (
  <StyledImageBackground pb={[0, 55]}>
    <Container>
      <Row>
        <Col>
          <Breadcrumbs mt={[20, 'xxl']} color="white" itemScope itemType="http://schema.org/BreadcrumbList">
            {breadcrumbs.map(({ node }, i) => (
              <li key={node.slug} itemScope itemProp="itemListElement" itemType="http://schema.org/ListItem">
                <Link NavComponent={NavLinkComponent} to={node.slug} itemProp="item"><Box as="span" itemProp="name">{node.menuTitle}</Box></Link>
                <meta itemProp="position" content={i + 1} />
              </li>
            ))}
          </Breadcrumbs>
        </Col>
      </Row>
      <Row alignItems="center" pt={['l', 0]} pb="85px">
        <Col width={[1, null, 'auto']} textAlign={['center', null, 'left']} mb={[0, '50px', 0]}>
          <Title as="h1" size="hero" color="white" mb={['10px', '20px']}>{ headerTitle }</Title>
          <BodyTitle color="gold.400" mb={0}>Dine early, save money.</BodyTitle>
        </Col>
        <Col width={[1, null, 'contains']} display={['none', 'block']}>
          <Box position="relative" width="407px" ml="auto" mr={[null, 'auto', 0]}>
            <HeroCTABackground />
            <HeroCTA>
              <HeroCTABody>
                <Title as="h3" size="h4">Early bird dining</Title>
                <Text fontSize="l">
                  Book the first table at
                  {' '}
                  { headerTitle }
                  {' '}
                  restaurants and get 50% off the food bill
                  {' '}
                  for two, three or four people!
                </Text>
                <FaqLink NavComponent={NavLinkComponent} to="/frequently-asked-questions/">
                  <Flex
                    bg="brand.red"
                    borderRadius="36px"
                    height="64px"
                    width="64px"
                    alignItems="center"
                    justifyContent="flex-end"
                    overflow="hidden"
                    color="white"
                    textAlign="center"
                    className="expandable-pill"
                  >
                    <Flex width="185px" className="arrow">
                      <Box width="70%" pl="25px" fontWeight={600}>Learn more</Box>
                      <Flex width="30%" pr="9px" alignItems="center" justifyContent="center">
                        <Icon name="m-arrow-right" color="inherit" />
                      </Flex>
                    </Flex>
                  </Flex>
                </FaqLink>
              </HeroCTABody>
            </HeroCTA>
          </Box>
        </Col>
      </Row>
    </Container>
  </StyledImageBackground>
);

CityHero.propTypes = {
  headerTitle: PropTypes.string,
  breadcrumbs: PropTypes.array,
};
CityHero.defaultProps = {
  headerTitle: '',
  breadcrumbs: [],
};

export default CityHero;
