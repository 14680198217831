import React from 'react';
import PropTypes from 'prop-types';
import { Box, Col, Row, Title } from '@firsttable/web-components/atoms';
import { graphql, useStaticQuery } from 'gatsby';
import HorizontalCard from '@firsttable/web-components/organisms/HorizontalCard';
import { Slider } from '../RestaurantOther';
import NavLink from '../../atoms/NavLink';

const subTitle = (session) =>
  `Book a First Table for ${session} and get 50% off the food bill`;

const CityOtherSessions = ({ city, sessionType }) => {
  const hasBreakfastDeals = city.breakfastRestaurants.edges.length > 0;
  const hasLunchDeals = city.lunchRestaurants.edges.length > 0;
  const { breakfast, lunch, dinner, stayhere, queenstownwinerytours } =
    useStaticQuery(graphql`
      query {
        breakfast: file(relativePath: { eq: "city-breakfast.jpg" }) {
          childImageSharp {
            fixed(width: 100, height: 100, quality: 70, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        lunch: file(relativePath: { eq: "city-lunch.jpg" }) {
          childImageSharp {
            fixed(width: 100, height: 100, quality: 70, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        dinner: file(relativePath: { eq: "city-dinner.jpg" }) {
          childImageSharp {
            fixed(width: 100, height: 100, quality: 70, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        stayhere: file(
          relativePath: { eq: "logos/stay-here-queenstown-holiday-homes.png" }
        ) {
          childImageSharp {
            fixed(width: 100, height: 100, quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        queenstownwinerytours: file(
          relativePath: { eq: "logos/queenstown-winery-tours.png" }
        ) {
          childImageSharp {
            fixed(width: 100, height: 100, quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `);

  const width = 400;

  return (
    <>
      {(hasBreakfastDeals || hasLunchDeals) && (
        <>
          <Row>
            <Col>
              <Box
                textAlign={['center', null, 'left']}
                display={[null, null, 'flex']}
                alignItems="center"
                justifyContent="space-between"
                mt={['50px']}
                mb={['20px', '30px']}
              >
                <Title textAlign="center" color="brand.blue" m={0}>
                  You might also like
                </Title>
              </Box>
            </Col>
          </Row>
          <Box>
            <Slider mx={['-16px', 0]} px={['16px', 0]}>
              {sessionType !== 'dinner' && (
                <Box mr={20} mb={60} width={width}>
                  <HorizontalCard
                    item={{
                      title: `${city.menuTitle} dinner deals`,
                      slug: city.slug,
                    }}
                    subTitle={subTitle('dinner')}
                    dataImg={dinner.childImageSharp.fixed}
                    NavLinkComponent={NavLink}
                  />
                </Box>
              )}
              {city.breakfastRestaurants.edges.length > 0 &&
                sessionType !== 'breakfast' && (
                  <Box mr={20} mb={60} width={width}>
                    <HorizontalCard
                      item={{
                        title: `${city.menuTitle} breakfast deals`,
                        slug: `${city.slug}breakfast/`,
                      }}
                      subTitle={subTitle('breakfast')}
                      dataImg={breakfast.childImageSharp.fixed}
                      NavLinkComponent={NavLink}
                    />
                  </Box>
                )}
              {city.lunchRestaurants.edges.length > 0 &&
                sessionType !== 'lunch' && (
                  <Box mr={20} mb={60} width={width}>
                    <HorizontalCard
                      item={{
                        title: `${city.menuTitle} lunch deals`,
                        slug: `${city.slug}lunch/`,
                      }}
                      subTitle={subTitle('lunch')}
                      dataImg={lunch.childImageSharp.fixed}
                      NavLinkComponent={NavLink}
                    />
                  </Box>
                )}
              {city.regionId === 6 && (
                <>
                  <Box mr={20} mb={60} width={width}>
                    <HorizontalCard
                      item={{
                        title: 'Queenstown Winery Tours',
                        href: 'https://www.queenstownwinerytours.com/',
                      }}
                      subTitle="Fun, flexible and hassle-free wine touring"
                      dataImg={queenstownwinerytours.childImageSharp.fixed}
                      NavLinkComponent={NavLink}
                    />
                  </Box>
                  <Box mr={20} mb={60} width={width}>
                    <HorizontalCard
                      item={{
                        title: 'Queenstown Holiday Homes',
                        href: 'https://www.stayhere.co.nz/',
                      }}
                      subTitle="Find your Queenstown home away from home"
                      dataImg={stayhere.childImageSharp.fixed}
                      NavLinkComponent={NavLink}
                    />
                  </Box>
                </>
              )}
            </Slider>
          </Box>
        </>
      )}
    </>
  );
};

CityOtherSessions.propTypes = {
  city: PropTypes.shape().isRequired,
  sessionType: PropTypes.string.isRequired,
};

export default CityOtherSessions;
