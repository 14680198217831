import RestaurantMapView from '../../organisms/RestaurantMap';
import SearchFilters from '../../organisms/RestaurantSearchFilters';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';
import Div100vh from 'react-div-100vh';
import { MobileFilter } from '@firsttable/web-components/molecules';
import { MobileFilterBtn } from '@firsttable/web-components/molecules';
import { Box, Col, Container, Row } from '@firsttable/web-components/atoms';
import ModalContext from '../../../context/ModalContext';

const ModalMap = ({
  selectedDate,
  session,
  restaurantsToShow,
  restaurantIds,
  userLat,
  userLng,
  handleMapView,
  ...props
}) => {
  const { showModalFunc, hideModalFunc } = useContext(ModalContext);
  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        handleMapView();
      }
    },
    [handleMapView],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  
  // This will sort onHold restaurants to the front of the stack (which will mean they get drawn first)
  // Comparison converted to boolean to use coercion sorting
  const sortedRestaurants = [...restaurantsToShow].sort(
    (a, b) => (a.status !== 'On Hold') - (b.status !== 'On Hold')
  );


  return (
    <Container maxWidth={null} pl={null} pr={null}>
      <Row gap={0}>
        <Col width={[1, null, 'auto']}>
          <Div100vh>
            <RestaurantMapView
              p="20px"
              selectedDate={selectedDate}
              session={session}
              restaurants={sortedRestaurants}
              restaurantIds={restaurantIds}
              userLat={userLat}
              userLng={userLng}
              bottomspace={90}
            />
          </Div100vh>
        </Col>
        <Col display={['none', null, 'block']} width={[1, null, 'contains']}>
          <Box width={[null, null, 300]} p="32px 32px 0">
            <SearchFilters
              handleMapView={() => handleMapView()}
              showMap
              {...props}
            />
          </Box>
        </Col>
      </Row>
      <MobileFilter display={['flex', null, 'none']}>
        <MobileFilterBtn
          kind="left"
          onClick={() => handleMapView()}
          icon="m-close-m-bold"
        />
        <MobileFilterBtn
          kind="right"
          icon="m-filter"
          onClick={() =>
            showModalFunc(
              <Box p="24px 24px 70px" position="relative">
                <SearchFilters
                  handleMapView={() => hideModalFunc()}
                  showMap={false}
                  {...props}
                />
                <MobileFilter position="fixed" onClick={() => hideModalFunc()}>
                  <MobileFilterBtn kind="left" icon="m-map" />
                  <MobileFilterBtn kind="right" icon="m-close-m-bold" />
                </MobileFilter>
              </Box>,
              { disableCloseBtn: true, type: 'none' },
            )
          }
        />
      </MobileFilter>
    </Container>
  );
};

ModalMap.defaultProps = {
  restaurantsToShow: [],
  selectedDate: '',
  session: '',
  restaurantIds: [],
  userLat: 0,
  userLng: 0,
  handleMapView: () => {},
};
ModalMap.propTypes = {
  restaurantsToShow: PropTypes.array,
  selectedDate: PropTypes.string,
  session: PropTypes.string,
  restaurantIds: PropTypes.array,
  userLat: PropTypes.number,
  userLng: PropTypes.number,
  handleMapView: PropTypes.func,
};

export default ModalMap;
